import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ViewerHeader from "./ViewerHeader";
import BlueprintViewer from "./BlueprintViewer";
import { doc, getDoc } from "firebase/firestore"; // Firestore imports
import { db } from "../../../services/firebase-config"; // Adjust path as needed

const ViewBlueprintPage: React.FC = () => {
  const navigate = useNavigate();
  const { projectId, blueprintName } = useParams<{
    projectId: string;
    blueprintName: string;
  }>();

  const [totalPages, setTotalPages] = useState<number>(1); // Number of pages in the blueprint
  const [currentPage, setCurrentPage] = useState<number>(0); // Current page we're viewing

  useEffect(() => {
    const fetchBlueprintPages = async () => {
      if (projectId && blueprintName) {
        const docRef = doc(db, "projects", projectId, "documents", blueprintName);
        const docSnapshot = await getDoc(docRef);
        if (docSnapshot.exists()) {
          const data = docSnapshot.data();
          if (data && data.pages) {
            setTotalPages(data.pages.length); // Make sure this is correctly fetching the number of pages
          }
        }
      }
    };
    fetchBlueprintPages();
  }, [projectId, blueprintName]);
  
  // Handlers for page navigation
  const goToPreviousPage = () => {
    setCurrentPage((prev) => Math.max(prev - 1, 0));
  };

  const goToNextPage = () => {
    setCurrentPage((prev) => Math.min(prev + 1, totalPages - 1));
  };

  if (!projectId || !blueprintName) {
    return <div>Error: Missing project ID or blueprint name</div>;
  }

  return (
    <div className="blueprint-page-container">
      <ViewerHeader
        navigate={navigate}
        blueprintName={blueprintName}
        currentPage={currentPage}
        totalPages={totalPages}
        goToPreviousPage={goToPreviousPage}
        goToNextPage={goToNextPage}
      />
      <BlueprintViewer
        projectId={projectId}
        blueprintName={blueprintName}
        currentPage={currentPage} // Pass current page to the viewer
      />
    </div>
  );
};

export default ViewBlueprintPage;
