import { Worker, Viewer, SpecialZoomLevel } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css"; // Import required styles

import React, { useState, useEffect } from "react";
import FloatingMenu from "./FloatingMenu";
import { getDoc, doc } from "firebase/firestore"; // Firestore import
import { db } from "../../../services/firebase-config"; // Adjust the path as needed

interface BlueprintViewerProps {
  projectId: string;
  blueprintName: string;
  currentPage: number; // This is the current page we're viewing
}

const BlueprintViewer: React.FC<BlueprintViewerProps> = ({
  projectId,
  blueprintName,
  currentPage,
}) => {
  const [blueprintUrl, setBlueprintUrl] = useState<string>("");


  useEffect(() => {
    const fetchBlueprint = async () => {
      try {
        const docRef = doc(db, "projects", projectId, "documents", blueprintName);
        const docSnapshot = await getDoc(docRef);
  
        if (docSnapshot.exists()) {
          const data = docSnapshot.data();
          console.log("Document data:", data); // Add this log to verify the structure of the data
          if (data && data.url) {
            console.log("Fetched blueprint URL:", data.url);
            setBlueprintUrl(data.url); // Ensure the URL is being set correctly
          } else {
            console.error("No URL found in document data.");
          }
        } else {
          console.error("Document does not exist.");
        }
      } catch (error) {
        console.error("Error fetching blueprint:", error);
      }
    };
  
    fetchBlueprint();
  }, [projectId, blueprintName]);
  

  return (
    <div className="blueprint-viewer-container">
      <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
        <div className="blueprint-viewer">
          {blueprintUrl && (
            <Viewer
              fileUrl={blueprintUrl}
              defaultScale={SpecialZoomLevel.PageFit}
              initialPage={currentPage} // Set the current page
              withCredentials={false} // Ensure Firebase file URL works without CORS issues
            />
          )}
        </div>
      </Worker>
      <FloatingMenu />
    </div>
  );
};

export default BlueprintViewer;
