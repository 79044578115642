import React from "react";
import { IoArrowBack } from "react-icons/io5"; // Left arrow icon
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai"; // Left and right navigation arrows

interface ViewerHeaderProps {
  navigate: any;
  blueprintName: string;
  currentPage: number;
  totalPages: number;
  goToPreviousPage: () => void;
  goToNextPage: () => void;
}

const ViewerHeader: React.FC<ViewerHeaderProps> = ({
  navigate,
  blueprintName,
  currentPage,
  totalPages,
  goToPreviousPage,
  goToNextPage,
}) => {
  return (
    <div className="sticky text-base top-0 z-50 bg-gray-200 px-4 py-4 flex justify-between items-center">
      <div className="flex items-center space-x-2">
        <button onClick={() => navigate(-1)} className="flex items-center">
          <IoArrowBack size={20} />
        </button>
        <button onClick={goToPreviousPage} disabled={currentPage === 0}>
          <AiOutlineArrowLeft size={24} />
        </button>
      </div>

      <div className="text-lg flex items-center space-x-2">
  <span>{blueprintName}</span>
  <span>{`Page ${currentPage + 1} of ${totalPages}`}</span>
</div>
      <div className="flex items-center space-x-2">
        <button
          onClick={goToNextPage}
          disabled={currentPage === totalPages - 1}
        >
          <AiOutlineArrowRight size={24} />
        </button>
        <button className="p-1 w-fit bg-blue-500 text-white rounded">
          Set Scale
        </button>
      </div>
    </div>
  );
};

export default ViewerHeader;
