import { db, storage } from "./firebase-config"; // Adjust this import based on your actual Firebase config path
import {
  doc,
  addDoc,
  collection,
  deleteDoc,
  FirestoreError,
  Timestamp,
  updateDoc,
  getDoc,
  increment,
  query,
  where,
  getDocs
} from "firebase/firestore";
import {
  ref,
  uploadBytes,
  getDownloadURL,
  deleteObject,
  updateMetadata
} from "firebase/storage";
import { getAuth } from "firebase/auth";
import { EstimateType, LineItem, DocumentType } from "../types/Project"; // Adjust types import based on your actual types definitions path
import { PDFDocument } from "pdf-lib";

const auth = getAuth();

/**
 * Upload a file to Firebase Storage and save its metadata to Firestore.
 * @param projectId The ID of the project to which the document is uploaded.
 * @param file The file to be uploaded.
 * @param fileName The name of the file.
 */
export const uploadFile = async (projectId: string, file: File, fileName: string) => {
  const user = auth.currentUser;
  if (!user) {
    throw new Error("User not authenticated");
  }

  console.log("Starting file upload process...");

  // Load the PDF and extract page information
  const pdfDoc = await PDFDocument.load(await file.arrayBuffer());
  const pages = pdfDoc.getPages();
  const pageNames = pages.map((_, index) => `Page ${index + 1}`);

  console.log("Extracted pages:", pageNames); // Log extracted page names

  const storageRef = ref(storage, `projects/${projectId}/${fileName}`);
  const metadata = {
    customMetadata: {
      owner: user.uid, // Store the owner's UID in metadata
    },
  };

  // Upload the file to Firebase Storage
  await uploadBytes(storageRef, file, metadata);
  console.log("File uploaded to storage:", fileName); // Log after successful upload

  const newDocument: DocumentType = {
    name: fileName,
    owner: user.uid, // TypeScript should now recognize this
    pages: await Promise.all(pageNames.map(async (name, index) => ({
      id: `page-${index}`,
      name,
      url: await getDownloadURL(storageRef), // Assuming all pages share the same file URL
      estimateItems: []
    }))),
  };
  
  // Add the document to Firestore
  const docRef = await addDoc(collection(db, "projects", projectId, "documents"), newDocument);
  console.log("Document added to Firestore:", docRef.id); // Log Firestore document ID

  // Save the document ID back to Firestore
  await updateDoc(docRef, { docId: docRef.id });
  console.log("Document ID saved to Firestore:", docRef.id); // Log after updating the document ID
};

/**
 * Delete a document from Firestore and its associated file from Firebase Storage.
 * @param projectId The ID of the project.
 * @param docId The ID of the document to delete.
 * @param fileName The name of the file to delete.
 */
export const deleteDocument = async (
  projectId: string,
  docId: string,
  fileName: string
): Promise<void | FirestoreError> => {
  try {
    const user = auth.currentUser;

    if (!user) {
      throw new Error("User not authenticated");
    }

    const docRef = doc(db, "projects", projectId, "documents", docId);
    const docSnapshot = await getDoc(docRef);

    if (!docSnapshot.exists()) {
      throw new Error("Document does not exist");
    }

    const docData = docSnapshot.data();
    if (docData?.owner !== user.uid) {
      throw new Error("You are not authorized to delete this document");
    }

    await deleteDoc(docRef);

    const storageRef = ref(storage, `projects/${projectId}/${fileName}`);
    await deleteObject(storageRef);
  } catch (error) {
    console.error("Error deleting document:", error);
    throw error as FirestoreError;
  }
};

/**
 * Update the document name in Firestore and Firebase Storage.
 * @param projectId The ID of the project.
 * @param docId The ID of the document to update.
 * @param newName The new name of the document.
 */
export const updateDocumentName = async (
  projectId: string,
  docId: string,
  newName: string
): Promise<void | FirestoreError> => {
  try {
    const docRef = doc(db, "projects", projectId, "documents", docId);
    const docSnapshot = await getDoc(docRef);

    if (!docSnapshot.exists()) {
      throw new Error("Document does not exist");
    }

    const docData = docSnapshot.data();
    const oldName = docData.name;

    // Update Firestore document
    await updateDoc(docRef, { name: newName });

    // Rename file in Firebase Storage
    const oldFileRef = ref(storage, `projects/${projectId}/${oldName}`);
    const newFileRef = ref(storage, `projects/${projectId}/${newName}`);
    const file = await getDownloadURL(oldFileRef);
    const response = await fetch(file);
    const blob = await response.blob();
    await uploadBytes(newFileRef, blob);
    await deleteObject(oldFileRef);

    // Update URL in Firestore
    const newFileURL = await getDownloadURL(newFileRef);
    await updateDoc(docRef, { url: newFileURL });
  } catch (error) {
    return error as FirestoreError;
  }
};

/**
 * Update a page name in Firestore.
 * @param projectId The ID of the project.
 * @param docId The ID of the document to update.
 * @param pageIndex The index of the page to update.
 * @param newName The new name of the page.
 */
export const updatePageName = async (
  projectId: string,
  docId: string,
  pageIndex: number,
  newName: string
): Promise<void | FirestoreError> => {
  try {
    const docRef = doc(db, "projects", projectId, "documents", docId);
    const docSnapshot = await getDoc(docRef);

    if (!docSnapshot.exists()) {
      throw new Error("Document does not exist");
    }

    const docData = docSnapshot.data();
    const updatedPages = [...docData.pages];
    updatedPages[pageIndex] = newName;

    await updateDoc(docRef, { pages: updatedPages });
  } catch (error) {
    return error as FirestoreError;
  }
};

/**
 * Add a new estimate to a project.
 * @param projectId The ID of the project to which the estimate is added.
 * @param estimateData Data of the new estimate to add.
 */
export const addEstimate = async (
  projectId: string,
  estimateData: EstimateType
): Promise<void | FirestoreError> => {
  try {
    await addDoc(
      collection(db, "projects", projectId, "estimates"),
      estimateData
    );
  } catch (error) {
    return error as FirestoreError;
  }
};

/**
 * Creates a new project and an initial estimate within that project.
 * @param projectData Data for the new project.
 * @param userId The ID of the user creating the project.
 * @returns The ID of the newly created project.
 */
export const createProjectAndEstimate = async (
  projectData: any,
  userId: string
) => {
  try {
    const projectRef = await addDoc(collection(db, "projects"), {
      ...projectData,
      userId: userId,
      createdAt: Timestamp.now(),
    });

    // Add initial estimate with empty items
    const estimateData = {
      title: projectData.projectName,
      createdAt: Timestamp.now(),
      createdBy: userId,
      items: [],
    };

    await addDoc(
      collection(db, "projects", projectRef.id, "estimates"),
      estimateData
    );

    // Increment totalBidsCreated field in the user's document
    const userRef = doc(db, "users", userId);
    await updateDoc(userRef, {
      totalBidsCreated: increment(1),
    });

    return projectRef.id; // Returns the new project ID for routing or other uses
  } catch (error) {
    if (error instanceof Error) {
      throw new Error(
        "Failed to create project and estimate: " + error.message
      );
    } else {
      throw new Error(
        "Failed to create project and estimate: An unknown error occurred"
      );
    }
  }
};

/**
 * Add items to an existing estimate.
 * @param projectId The ID of the project.
 * @param estimateId The ID of the estimate to which items are added.
 * @param items Array of items to add to the estimate.
 */
export const addEstimateItems = async (
  projectId: string,
  estimateId: string,
  items: LineItem[]
): Promise<void | FirestoreError> => {
  try {
    const estimateRef = doc(db, "projects", projectId, "estimates", estimateId);
    await updateDoc(estimateRef, {
      items: items,
    });
  } catch (error) {
    return error as FirestoreError;
  }
};

/**
 * Update an existing estimate item.
 * @param projectId The ID of the project.
 * @param estimateId The ID of the estimate.
 * @param itemId The ID of the item to update.
 * @param itemData New data for the item.
 */
export const editEstimateItem = async (
  projectId: string,
  estimateId: string,
  itemId: string,
  itemData: Partial<LineItem>
): Promise<void | FirestoreError> => {
  try {
    const itemRef = doc(
      db,
      "projects",
      projectId,
      "estimates",
      estimateId,
      "items",
      itemId
    );
    await updateDoc(itemRef, itemData);
  } catch (error) {
    return error as FirestoreError;
  }
};

/**
 * Delete an item from an estimate.
 * @param projectId The ID of the project.
 * @param estimateId The ID of the estimate.
 * @param itemId The ID of the item to delete.
 */
export const deleteEstimateItem = async (
  projectId: string,
  estimateId: string,
  itemId: string
): Promise<void | FirestoreError> => {
  try {
    const itemRef = doc(
      db,
      "projects",
      projectId,
      "estimates",
      estimateId,
      "items",
      itemId
    );
    await updateDoc(itemRef, {
      deleted: true, // Soft delete; set to remove the document if you prefer hard deletes
    });
  } catch (error) {
    return error as FirestoreError;
  }
};